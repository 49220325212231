$grid-gutter-width: 12px;

@import "../node_modules/frow/frow";

body {
  background-color: white;
  background-image: url("https://skele.objkt.com/file/common-skele/bg.png");
  color: #010101;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  letter-spacing: 0.3px;
}

p, ul, ol {
  a {
    color: #642fa1;
    text-decoration: underline;

    &:hover {
      color: #280e46;
    }
  }
}

#wallet-id {
  display: none;
}

#unsync {
  display: none;
}

section.text {
  position: relative;
  padding: 1rem 1.5rem;

  h2 {
    margin-top: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 26px;
    margin: 0.85rem 0;
  }

  ul,
  ol {
    font-size: 1.2rem;

    li {
      margin-bottom: 8px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    opacity: 0.8;
    z-index: -1;
  }
}

p {
  line-height: 1.85rem;
}

.header {
  .links a {
    font-weight: bold;
    font-size: 1rem;
  }
}

.minting {
  margin-top: 50px;
  min-height: 23px;

  #mint-buttons {
    display: none;
  }
  #view-on-objkt {
    display: none;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
  }
}

.logo {
  @media (max-width: 767px) {
   font-size: 1.3rem;
  }
}

.grid-button {
  display: inline-block;
  background: radial-gradient(circle, #ffeefe 0%, #fffcff 100%);
  font-size: 1.6rem;
  font-weight: bold;
  box-shadow: -15px 15px 15px rgb(0 0 0 / 40%);

  p {
    margin: 10px 0;
    text-shadow: 1px 1px 1px rgb(255 255 255 / 60%),
      -1px -1px 1px rgb(255 255 255 / 60%);
  }
}

.grid {
  position: relative;
  padding: 15px 28px;
  // background-color: rgb(206, 235, 255);
  background-image: repeating-linear-gradient(
      #66b0ff 0.0001px 2px,
      transparent 2px 100%
    ),
    repeating-linear-gradient(90deg, #8cc1fa 0.0001px 2px, transparent 2px 100%);
  background-size: 15px 15px;

  &:after {
    content: "";
    position: absolute;
    background-color: #d0affd;
    width: 8px;
    height: 100%;
    transform: skewY(-45deg);
    left: -8px;
    top: 5px;
    background-image: repeating-linear-gradient(
        #4373a7 0.0001px 2px,
        transparent 2px 100%
      ),
      repeating-linear-gradient(
        90deg,
        #4373a7 0.0001px 2px,
        transparent 2px 100%
      );
    background-size: 15px 15px;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: #72618b;
    height: 8px;
    width: 100%;
    transform: skewX(-45deg);
    left: -5px;
    bottom: -8px;
    background-image: repeating-linear-gradient(
        #213e5c 0.0001px 2px,
        transparent 2px 100%
      ),
      repeating-linear-gradient(
        90deg,
        #213e5c 0.0001px 2px,
        transparent 2px 100%
      );
    background-size: 15px 15px;
  }
}

.window {
  user-select: none;

  // perspective: 800px;
  transform-style: preserve-3d;
  position: relative;
  // perspective: 600px;
  // min-height: 200px;
  padding: 20px;
  // background-color: rgb(206, 235, 255);
  transform-style: preserve-3d;
  height: 342px;
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.2),
    10px -10px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 991px) {
    h3 {
      font-size: 7rem !important;
    }

    h3#window-closed {
      font-size: 5rem !important;
    }

    .gatorade {
      left: 33% !important;
    }
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 4rem !important;
    }

    h3#window-closed {
      font-size: 2.8rem !important;
    }
  }

  @media (max-width: 422px) {
    height: 242px;
    h3 {
      font-size: 2rem !important;
    }

    h3#window-closed {
      font-size: 2rem !important;
    }

    .plant {
      display: none;
    }
    .skelly-dance {
      bottom: -26px;
      img {
        width: 200px !important;
        height: 200px !important;
      }
    }
  }

  h3 {
    margin-top: -2rem;
    font-size: 10rem;
    text-align: center;
    opacity: 0.8;
  }

  h3#window-closed {
    font-size: 6rem;
    display: none;
  }

  h3#coutndown-text {
    display: none;
  }

  .plant {
    position: absolute;
    left: 2%;
    bottom: -10px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .gatorade {
    position: absolute;
    left: 28%;
    bottom: 0;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .avocado {
    position: absolute;
    left: 12%;
    bottom: -8px;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .skelly-dance {
    position: absolute;
    right: -10px;
    bottom: -36px;

    img {
      width: 250px;
      height: 250px;
    }
  }

  .scene {
    width: 100%;
    height: 100%;
    perspective: 800px;
  }

  .box {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-50px);
  }

  .box__face {
    position: absolute;
    font-size: 1.4rem;
    line-height: 2rem;

    p {
      margin: 10px 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: repeating-linear-gradient(
          #66b0ff 0.0001px 2px,
          transparent 2px 100%
        ),
        repeating-linear-gradient(
          90deg,
          #8cc1fa 0.0001px 2px,
          transparent 2px 100%
        );
      background-size: 15px 15px;
      background-position: 5px 5px;
      z-index: -1;
    }
  }

  .box__face--front,
  .box__face--back {
    width: 100%;
    height: 100%;
  }

  .box__face--right,
  .box__face--left {
    width: 100px;
    height: 100%;
    left: 100px;
  }

  .box__face--top,
  .box__face--bottom {
    width: 100%;
    height: 100px;
    top: 51px;
  }

  .box__face--front {
    background: hsla(0, 100%, 50%, 0.7);
  }
  .box__face--right {
    left: auto;
    right: 100px;
    background: linear-gradient(
      180deg,
      rgba(244, 227, 248, 1) 0%,
      rgba(222, 232, 237, 1) 52%,
      rgba(254, 240, 255, 1) 100%
    );

    &:after {
      background-position: -13px 15px;
    }
  }
  .box__face--back {
    background: rgb(205, 161, 255);
    background: linear-gradient(
      156deg,
      rgba(205, 161, 255, 1) 0%,
      rgba(228, 247, 245, 1) 52%,
      rgba(176, 201, 206, 1) 100%
    );

    .content {
      position: absolute;
      background-image: url("https://skele.objkt.com/file/common-skele/bg.png");
      left: 17px;
      top: 17px;
      right: 20px;
      bottom: 17px;
      box-shadow: inset 0 0 0px 3px rgba(139, 193, 250, 0.4);
      color: black;
      padding: 10px 20px;
      z-index: 1;
      height: auto;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #e8f1f9;
        filter: blur(3px);
        opacity: 0.6;
      }
    }
  }
  .box__face--left {
    background: linear-gradient(
      180deg,
      rgba(244, 227, 248, 1) 0%,
      rgba(247, 224, 253, 1) 52%,
      rgba(183, 162, 254, 1) 100%
    );

    &:after {
      background-position: -9px 15px;
    }
  }
  .box__face--top {
    background: linear-gradient(
      90deg,
      rgba(245, 232, 253, 1) 0%,
      rgba(251, 241, 253, 1) 43%,
      rgba(232, 215, 247, 1) 100%
    );
    &:after {
      background-position: 0px 6px;
    }
  }
  .box__face--bottom {
    top: auto;
    bottom: 52px;
    background: linear-gradient(
      90deg,
      rgba(205, 161, 255, 1) 0%,
      rgba(228, 247, 245, 1) 52%,
      rgba(253, 238, 254, 1) 100%
    );
    &:after {
      background-position: 0px 4px;
    }
  }

  .box__face--front {
    transform: rotateY(0deg) translateZ(50px);
  }
  .box__face--back {
    transform: translateZ(-50px);

    &:after {
      background-position: 0px 0px;
    }
  }

  .box__face--right {
    transform: rotateY(90deg) translateZ(150px);
  }
  .box__face--left {
    transform: rotateY(-90deg) translateZ(150px);
  }

  .box__face--top {
    transform: rotateX(90deg) translateZ(100px);
  }
  .box__face--bottom {
    transform: rotateX(-90deg) translateZ(100px);
  }

  .window-content {
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(
        #66b0ff 0.0001px 2px,
        transparent 2px 100%
      ),
      repeating-linear-gradient(
        90deg,
        #8cc1fa 0.0001px 2px,
        transparent 2px 100%
      );
    background-size: 15px 15px;
    background-position: 5px 5px;
    z-index: -1;
  }
}

.color-1 {
  background: radial-gradient(
    circle,
    rgba(255, 238, 254, 1) 0%,
    rgba(255, 252, 255, 1) 100%
  );
}

button {
  font-size: 1.2rem;
  box-shadow: -30px 30px 15px rgb(0 0 0 / 40%);
}

section.info {
  padding: 0.1rem 1rem;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    opacity: 0.7;
    z-index: -1;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.63rem;
    font-weight: 400;
  }
}

section.previews {
  margin-top: 50px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  .skele {
    margin-bottom: 12px;

    img {
      min-height: auto;
      width: 100%;
      background: transparent;
      opacity: 1;
      filter: blur(0);
      transition: opacity 0.1s ease-in-out, background 0.1s ease-in-out,
        filter 0.1s ease-in-out;
    }

    img:not([data-src=""]) {
      min-height: 100%;
      padding-top: 100%;
      background: white;
      opacity: 0.3;
      filter: blur(3px);
    }
  }
}

#unsync-wallet {
  display: none;
}

#mint-area,
#wait-area {
  display: none;

  button {
    padding: 10px 15px;
    font-size: 1.5rem;
  }
}

#sold-out {
  display: none;
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);

  img {
    width: 100px;
  }
}

.loader .loader-wrapper {
  background: rgba(255, 255, 255, 0.85);
  padding: 1rem;
}

.loader p {
  margin: 0;
  padding: 2rem;
}

button.windows {
  font-family: "Pixelated MS Sans Serif", Arial;
  -webkit-font-smoothing: none;
  font-size: 11px;

  box-sizing: border-box;
  border: none;
  background: #c0c0c0;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
    inset -2px -2px #808080, inset 2px 2px #dfdfdf;
  border-radius: 0;
  color: black;
  min-width: 75px;
  min-height: 23px;
  padding: 0 12px;
  transform: scale(2);

  @media (max-width: 767px) {
    transform: scale(1);
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}

button.windows:not(:disabled):active {
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a,
    inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  padding: 2px 11px 0 13px;
}

@media (not(hover)) {
  button.windows:not(:disabled):hover {
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a,
      inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  }
}

button.windows:focus {
  outline: 1px dotted #000000;
  outline-offset: -4px;
}

button.windows::-moz-focus-inner {
  border: 0;
}

:disabled,
:disabled + label {
  color: #808080;
}

button.windows:disabled,
:disabled + label {
  text-shadow: 1px 1px 0 #ffffff;
}

.filter-shadow {
  color: #312d40;
  background-clip: text;
  user-select: none;
  filter: drop-shadow(-1px -1px white) drop-shadow(1px 1px darken(white, 50%))
    drop-shadow(1px 1px darken(white, 52%))
    drop-shadow(1px 1px darken(white, 54%))
    drop-shadow(1px 1px darken(white, 56%))
    drop-shadow(1px 1px darken(white, 58%))
    drop-shadow(1px 1px darken(white, 60%))
    drop-shadow(1px 1px darken(white, 62%))
    drop-shadow(1px 1px darken(white, 64%))
    drop-shadow(1px 1px darken(white, 66%))
    drop-shadow(1px 1px darken(white, 68%))
    drop-shadow(1px 1px darken(white, 70%))
    drop-shadow(12px 12px 30px rgba(black, 0.2))
    drop-shadow(12px 12px 10px rgba(black, 0.2));

  @media (max-width: 422px) {
    filter: drop-shadow(-1px -1px white) drop-shadow(1px 1px darken(white, 50%))
      drop-shadow(1px 1px darken(white, 52%))
      drop-shadow(1px 1px darken(white, 54%))
      drop-shadow(1px 1px darken(white, 56%))
      drop-shadow(12px 12px 30px rgba(black, 0.2))
      drop-shadow(12px 12px 10px rgba(black, 0.2));
  }
}
